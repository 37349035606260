import React from 'react';
import styled from 'styled-components';
import { KineduPalette } from 'kinedu-react-components';
import { windowGlobal } from '../config';

const StyledFakeDap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
  background-color: ${KineduPalette.kinedu};
`;

const userEmail = windowGlobal.localStorage.getItem('user_email');
const orgName = windowGlobal.localStorage.getItem('school_name');

const FakeDap = () => (
  <StyledFakeDap>
    <h1>Logged in:</h1>
    <p>
      <strong>Email: </strong>
      {userEmail}
    </p>
    <p>
      <strong>Organization: </strong>
      {orgName}
    </p>
  </StyledFakeDap>
);

export default FakeDap;
